$( document ).ready(function() {

	if ($('.gallery a').length > 0) { 
		var lightbox = $('.gallery a').simpleLightbox({
			additionalHtml: '<a href="#"></a>'
		});
	}
	

	
	
    $(".navbar-toggle").on('click touch', function () {
        $(this).toggleClass('active');
		$('.nav-overlay').toggleClass('active');
		$('body').toggleClass('noscroll');
    });
	
	$('select').selectpicker({showIcon: true});


	$('.event-image-slider').each(function() {

		// VARS
		var $slickElement = $(this);
		
		var $slickNavi = $slickElement.closest('section').find('.event-indicator');
		
		var $navElement = $slickElement.closest('section').find('.event-card-slider')
		
		var $colorElement = $slickElement.closest('section').find('.event-color-slider')
	
		
		$slickElement.slick({
	  		adaptiveHeight: true,
	  		fade: true,
	  		infinite: true,
		    arrows: false,
			speed: 1000,
			autoplaySpeed: 5000,
		    autoplay: true,
			dots: true,
			appendDots: $slickNavi,
			asNavFor: ".event-color-slider,.event-card-slider"
		});
		
		
		$navElement.slick({
	  		adaptiveHeight: true,
	  		fade: true,
	  		infinite: true,
		    arrows: false,
			speed: 1000,
			autoplaySpeed: 5000,
			autoplay: true,
			asNavFor: ".event-color-slider,.event-image-slider"
		});
		
		// Check AsNavFor with two objects
		$colorElement.slick({
	  		fade: true,
	  		infinite: true,
		    arrows: false,
			speed: 1000,
			autoplaySpeed: 5000,
			autoplay: true,
			asNavFor: ".event-card-slider,.event-image-slider"
		});
	


	});

});

