import 'jquery';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/tab';
import 'bootstrap-select/js/bootstrap-select';

import 'slick-carousel/slick/slick';

import 'simplelightbox/dist/simple-lightbox';

import 'cookieconsent/src/cookieconsent';


import './parts/base'